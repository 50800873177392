<template>
  <div>
    <div class="search">
      <el-input
        @keyup.enter.native="handleSearch"
        class="int"
        v-model="keyword"
        placeholder="请输入关键字"
      ></el-input>
      <button class="btn" @click="handleSearch">查询</button>
    </div>
    <div class="loading" v-if="isLoading">
      <span>数据加载中...</span>
    </div>
    <div class="noMatch" v-if="showNoMatch">
      <span>没有搜索到匹配结果</span>
    </div>
    <div
      class="welfareContent"
      v-for="(item, index) in caseContent"
      :key="index"
      @click="goDetail(item)"
    >
      <img
        class="pic"
        :src="newBaseurl + item.imgPath"
        alt=""
        @error="handleNoImg"
      />
      <div class="content-detail">
        <h3 class="title">{{ item.title }}</h3>
        <div class="welfareItem">
          <span class="date-time">{{ item.publishTime }} &nbsp; 发布</span>
          <span class="catagory">分类：{{ item.projectClassify }}</span>
        </div>
        <p class="details" v-text="getText(item.content)"></p>
        <!-- <button class="btn" @click="goDetail(item)">查看详情</button> -->
      </div>
    </div>
    <el-pagination
      class="fenYe"
      background
      layout="prev, pager, next"
      :total="total"
      :page-size="pageSize"
      :current-page="pageIndex"
      @current-change="handlePageChange"
    >
    </el-pagination>
  </div>
</template>


<script>
export default {
  name: "case",
  data() {
    return {
      caseContent: [],
      pageIndex: 1,
      pageSize: 10,
      total: 0,
      keyword: "",
      catagory: 'S_IN0',
      showNoMatch: false,
      isLoading: false
    };
  },
  created(){
    this.pageIndex = parseInt(this.$route.params.page)
    this.catagory = this.$route.params.id
    this.keyword = this.$route.params.keyWord
    this.getCase()
  },
  watch: {
    '$route.params'(val) {
      this.pageIndex = parseInt(this.$route.params.page)
      this.catagory = this.$route.params.id
      this.keyword = this.$route.params.keyWord
      this.getCase()
    }
  },
  methods: {
    // 列表接口
    getCase() {
      this.isLoading = true
      this.showNoMatch = false
      this.$axios({
        method: "post",
        url: "/laf/laf/lafCharityProject/tableList.ajax",
        data: {
          pageNo: this.pageIndex,
          pageSize: this.pageSize,
          _entityName: "org.aweto.laf.entity.LafCharityProject",
          condition: this.keyword ? `title:@${this.keyword}|projectClassify:${this.catagory}`:`projectClassify:${this.catagory}`,
          orderCondition: "desc",
          orderField: "publishTime",
        },
      }).then((res) => {
          this.caseContent = res.rows;
          this.total = res.totalRows;
          this.isLoading = false
          if(this.caseContent.length){
            this.showNoMatch = false
          }else{
            this.showNoMatch = true
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    // 详情
    goDetail(e) {
      const { row_pkValue } = e;
      /* this.$router.push({
        path: "/welfareProject/weflDetail",
        query: {
          row_pkValue: row_pkValue,
        },
      }); */
      window.open(`./#/welfareProject/weflDetail?row_pkValue=${row_pkValue}`)
    },

    handlePageChange(index) {
      this.pageIndex = index;
      if(this.keyword){
        this.$router.push(`/welfareProject/${this.$route.params.id}/projectDynamic/${index}/${this.keyword}`)
      }else{
        this.$router.push(`/welfareProject/${this.$route.params.id}/projectDynamic/${index}/`)
      }
    },

    // 关键字查询接口
    handleSearch() {
      console.log(111)
      if(this.keyword){
        this.$router.push(`/welfareProject/${this.$route.params.id}/projectDynamic/1/${this.keyword}`)
      }else{
        this.$router.push(`/welfareProject/${this.$route.params.id}/projectDynamic/1/`)
      }
    },

    getText(content) {
      const dv = document.createElement("div");
      dv.innerHTML = content;
      return dv.innerText;
    },
  },
};
</script>

<style lang='scss' scoped>
.search {
  display: flex;
  margin-top: 36px;
  .btn {
    width: 100px;
    height: 35px;
    background-color: #cd242a;
    border-radius: 2px;
    border: none;
    color: #fff;
    font-size: 14px;
    font-weight: 700;
    outline: none;
    cursor: pointer;
  }
  ::v-deep.el-input__inner {
    width: 280px;
    height: 35px;
    border: 1px solid #e5e5e5;
    font-size: 14px;
    float: right;
    margin-right: 4px;
  }
}

.welfareContent {
  cursor: pointer;
  width: 100%;
  padding: 10px 28px 10px 18px;
  margin-top: 26px;
  margin-bottom: 25px;
  border: 1px solid #e5e5e5;
  @include apply-small{
    border-left: 0;
    border-top: 0;
    border-right: 0;
  }
  &:hover {
    background-color: #f8f8f8;
  }
  .pic {
    display: block;
    width: 180px;
    height: 190px;
    float: left;
    object-fit: cover;
    @include apply-small {
      height: 160px;
    }
  }
  .content-detail {
    height: 190px;
    margin-left: 190px;
    padding-left: 20px;
    box-sizing: border-box;
    @include apply-small {
      height: 160px;
    }
  }

  h3 {
    width: 100%;
    font-size: 20px;
    color: #333333;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    overflow: hidden;
  }
  .welfareItem {
    width: 100%;
    font-size: 14px;
    margin-top: 10px;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    overflow: hidden;
    @include apply-small {
      margin-top: 6px;
    }
  }
  .welfareItem :nth-child(1) {
    margin-right: 5%;
  }
  .details {
    font-size: 14px;
    margin-top: 20px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    @include apply-small {
      margin-top: 6px;
      color: #999;
    }
  }
  .date-time{
    @include apply-small {
      font-size: 14px;
      color: #999;
    }
  }
  .btn {
    width: 145px;
    height: 40px;
    background: #ffffff;
    // border: 1px solid #dd2020;
    margin-top: 10px;
    float: right;
    color: #fff;
    background-color: $red;
    border: 0;
    cursor: pointer;
    @include apply-small{
      display: none;
    }
  }
}
.fenYe {
  text-align: center;
  margin: 40px 0;
}
::v-deep .el-pagination.is-background .el-pager li:not(.disabled).active {
  background-color: $red;
}
.catagory{
  @include apply-small{
    display: none;
  }
}
.noMatch{
  padding-top: 30px;
  text-align: center;
  color: #999;
  font-size: 20px;
}
.loading{
  padding-top: 30px;
  text-align: center;
  color: #999;
  font-size: 20px;
}
</style>